<template>
  <div>
    <h3 class="mb-3">
      <v-icon class="pr-1">mdi-truck-outline</v-icon> Informações
    </h3>

    <v-row>
      <v-col>
        <small>Descrição:</small>
        <br />
        <b>
          {{ stockTransfer.description }}
        </b>
        <v-chip
          class="ml-5"
          small
          :color="statuses[stockTransfer.status].color"
          >{{ statuses[stockTransfer.status].text }}</v-chip
        >
      </v-col>
      <v-col
        cols="12"
        md="6"
        class="d-flex justify-space-between justify-md-end"
      >
        <v-btn
          class="mr-3"
          v-if="canReceive"
          @click="handleTransferReceipt()"
          color="success"
        >
          <v-icon left>mdi-truck-check-outline</v-icon>
          Receber
        </v-btn>

        <v-btn
          v-if="canEdit"
          @click="handleEditTransferReceipt(stockTransfer.id)"
          color="primary"
        >
          <app-icon left>edit</app-icon>
          Editar
        </v-btn>

        <v-btn @click="printTransfer(stockTransfer.id)" color="primary">
          <app-icon left>print</app-icon>
          Imprimir
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="pr-md-0">
        <v-card outlined>
          <v-card-title>
            <span class="headline">
              {{ stockTransfer.company_origin.name }}
            </span>
          </v-card-title>
          <v-card-text>
            Emitido por {{ stockTransfer.created_by.name }} em
            {{ $format.dateBr(stockTransfer.issued_at) }}
          </v-card-text>
        </v-card>
      </v-col>

      <div
        class="d-flex justify-center align-center col-md-1 col-12"
        style="padding: 0px"
      >
        <v-icon color="primary" class="d-none d-md-flex" size="50px">
          mdi-arrow-right-thick
        </v-icon>
        <v-icon color="primary" class="d-md-none d-flex" size="50px">
          mdi-arrow-down-thick
        </v-icon>
      </div>

      <v-col class="pl-md-0">
        <v-card outlined>
          <v-card-title>
            <span class="headline">
              {{ stockTransfer.company_destination.name }}
            </span>
          </v-card-title>
          <v-card-text>
            <span v-if="stockTransfer.received_by">
              Recebido por {{ stockTransfer.received_by.name }} em
              {{ $format.dateBr(stockTransfer.received_at) }}
            </span>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <h3 class="mt-6 mb-3">
      <v-icon class="pr-1">mdi-package-variant-closed</v-icon> Produtos
    </h3>
    <template v-if="!$vuetify.breakpoint.mobile">
      <v-row>
        <v-col>
          <v-data-table
            dense
            disable-sort
            hide-default-footer
            :items="products"
            :headers="headers"
            :items-per-page="-1"
            :server-items-length="-1"
          >
            <template v-slot:[`item.quantity`]="{ item }">
              {{ $format.decimal(item.quantity) }}
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </template>
    <template v-if="$vuetify.breakpoint.mobile">
      <v-list dense>
        <v-divider />
        <template v-for="(row, index) in products">
          <v-list-item :key="index">
            <v-list-item-content>
              <v-list-item-title> {{ row.product.name }} </v-list-item-title>
              <v-list-item-subtitle>
                Qt: {{ $format.decimal(row.quantity) }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider :key="`index-${index}`" />
        </template>
      </v-list>
    </template>

    <v-row class="my-2" v-if="stockTransfer.observations">
      <v-col>
        <h5>Observação:</h5>

        <b>{{ stockTransfer.observations }}</b>
      </v-col>
    </v-row>
    <br />
    <v-row>
      <v-col class="text-right">
        <v-btn
          @click="$router.go(-1)"
          class="text-capitalize mr-2"
          text
          color="secondary"
          >Voltar</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      stockTransfer: {
        company_origin: {},
        company_destination: {},
        created_by: {},
        received_by: {},
        stock_transfer_products: [],
        status: "draft",
      },
      statuses: {
        confirmed: {
          text: "Confirmado",
          color: "primary",
        },
        received: {
          text: "Recebido",
          color: "success",
        },
        draft: {
          text: "Rascunho",
          color: "",
        },
        canceled: {
          text: "Cancelado",
          color: "error",
        },
      },
      headers: [
        {
          width: "80%",
          align: "start",
          text: "Produto",
          value: "product.name",
        },
        {
          width: "20%",
          align: "center",
          text: "Quantidade",
          value: "quantity",
        },
        { align: "end", text: "", value: "action" },
      ],
    };
  },

  created() {
    this.select();
  },

  computed: {
    products() {
      return this.stockTransfer.stock_transfer_products;
    },
    canReceive() {
      return (
        this.stockTransfer.status == "confirmed" &&
        this.$acl.can("stockTransferReceive")
      );
    },

    canCancel() {
      return (
        this.stockTransfer.status == "confirmed" &&
        this.$acl.can("stockTransferCancel")
      );
    },

    canEdit() {
      return (
        this.stockTransfer.status == "draft" &&
        this.$acl.can("stockTransferUpdate")
      );
    },
  },

  methods: {
    select() {
      this.$loading.start();
      this.$http
        .show("stock/stock-transfer", this.$route.params.id)
        .then((response) => {
          this.stockTransfer = JSON.parse(JSON.stringify(response.transfer));
          this.$loading.finish();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },
    handleEditTransferReceipt() {
      this.$router.push({
        name: "UpdateStockTransfer",
        params: { id: this.stockTransfer.id },
      });
    },
    handleTransferReceipt() {
      this.$http
        .store("stock/stock-transfer/receipt", {
          id: this.stockTransfer.id,
        })
        .then((response) => {
          this.select();
        });
    },

    handleCancelTransferReceipt() {
      this.$http
        .$post("stock/stock-transfer/receipt-cancel", {
          id: this.stockTransfer.id,
        })
        .then((response) => {
          this.select();
        });
    },

    handleCancelTransfer() {
      this.$http
        .$post("stock/stock-transfer/cancel", {
          id: this.stockTransfer.id,
        })
        .then((response) => {
          this.select();
        });
    },
    printTransfer() {
      window.open(`/transferencia/imprimir/${this.stockTransfer.id}`);
    },
  },
};
</script>

<style>
</style>